import { Controller } from 'stimulus';

export default class TokenFieldController extends Controller {
  static targets = ['form', 'partition', 'result', 'status'];

  focusedField = () => document.activeElement;
  isFirstField = () => this.partitionTargets[0] === this.focusedField();
  isLastField = () => this.partitionTargets[3] === this.focusedField();

  submitForm = () => {
    const { formTarget, partitionTargets, resultTarget, statusTarget } = this;

    _.forEach(partitionTargets, (field) => {
      resultTarget.value += field.value;
      field.setAttribute('disabled', true);
    });
    statusTarget.innerHTML = '<p>Verifying, please wait...</p>';
    formTarget.submit();
  };

  addCharacter = (character) => {
    const { focusedField, isLastField, moveCursor, submitForm } = this;
    focusedField().value = character;
    isLastField() ? submitForm() : moveCursor(focusedField(), 'nextElementSibling');
  }

  removeCharacter = (charactersInField) => {
    const { focusedField, isFirstField, moveCursor } = this;

    if (!charactersInField && !isFirstField()) {
      focusedField().value = '';
      moveCursor(focusedField(), 'previousElementSibling');
    }
  }

  moveCursor = (field, sibling) => {
    const nextField = field[sibling];
    nextField.focus();
  }

  onInput = ({ data, inputType }) => {
    if (inputType === 'insertText' && _.isString(data)) this.addCharacter(data);
  }

  onKeydown = ({ key }) => {
    const { focusedField, isFirstField, isLastField, moveCursor, removeCharacter } = this;
    const charactersInField = focusedField().value !== '';

    if (key === 'Backspace') {
      removeCharacter(charactersInField);
    } else if (!isLastField() && key === 'ArrowRight') {
      moveCursor(focusedField(), 'nextElementSibling');
    } else if (!isFirstField() && key === 'ArrowLeft') {
      moveCursor(focusedField(), 'previousElementSibling');
    }
  }

  onPaste = (e) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('text');
    _.forEach(pastedText, (letter) => this.addCharacter(letter));
  }
}
